import { configureStore } from "@reduxjs/toolkit";
import globalInfoReducer from "./slices/GlobalInfoSlice";
import markInfoReducer from "./slices/MarkInfoSlice";
import markIdReducer from "./slices/MarkIDSlice";
import actionListReducer from "./slices/ActionListSlice";
import filtersReducer from "./slices/FilterSlice";
import lastUsedActionReducer from "./slices/LastUsedActionSlice";
import gameTimeReducer from "./slices/GameTimeSlice";

export default configureStore({
  reducer: {
    globalInfo: globalInfoReducer,
    markInfo: markInfoReducer,
    markId: markIdReducer,
    actionList: actionListReducer,
    filters: filtersReducer,
    lastUsedAction: lastUsedActionReducer,
    gameTime: gameTimeReducer,
  },
});
