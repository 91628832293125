import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  time: 1200000, // milliseconds
  isRunning: false,
};

// variable representing times in milliseconds
const secondInMilliseconds = 1000;
const minuteInMilliseconds = 60000;
const periodInMilliseconds = 1200000;

export const GameTimeSlice = createSlice({
  name: "gameTime",
  initialState,
  reducers: {
    // add seconds up until time is not higher than 20 minutes
    // at that point reset the timer to 0
    addSecond: (state) => {
      state.time + secondInMilliseconds > periodInMilliseconds
        ? (state.time = 0)
        : (state.time += secondInMilliseconds);
    },
    subtractSecond: (state) => {
      // subtract seconds until time is not lower or equal to 0
      // at that point reset the timer to 0
      state.time - secondInMilliseconds <= 0
        ? (state.time = 0)
        : (state.time -= secondInMilliseconds);
    },
    addMinute: (state) => {
      state.time + minuteInMilliseconds > periodInMilliseconds
        ? (state.time = 0)
        : (state.time += minuteInMilliseconds);
    },
    subtractMinute: (state) => {
      state.time - minuteInMilliseconds <= 0
        ? (state.time = 0)
        : (state.time -= minuteInMilliseconds);
    },
    setTime: (state, action) => {
      state.time = action.payload;
    },
    resetTo20Minutes: (state) => {
      state.time = periodInMilliseconds;
      state.isRunning = false;
    },
    startTimer: (state) => {
      state.isRunning = true;
    },
    pauseTimer: (state) => {
      state.isRunning = false;
    },
  },
});

export const {
  addSecond,
  subtractSecond,
  addMinute,
  subtractMinute,
  startTimer,
  pauseTimer,
  setTime,
  resetTo20Minutes,
} = GameTimeSlice.actions;

export default GameTimeSlice.reducer;
