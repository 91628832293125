import SubActionButton from "./SubActionButton";
import { useSelector } from "react-redux";

function Subaction() {
  const lastUsedAction = useSelector((state) => state.lastUsedAction);
  return (
    <div className="subaction">
      {lastUsedAction.button &&
        lastUsedAction.button.subAction.length > 0 &&
        lastUsedAction.button.subAction.map((button) => {
          return (
            <SubActionButton
              key={button.subaction_id}
              subaction_id={button.subaction_id}
              name={button.name}
              shortName={button.shortName}
            />
          );
        })}
    </div>
  );
}

export default Subaction;
