import React from "react";
import Action from "./Action";
import Subaction from "./Subaction";
import EventList from "./EventList";
import Reaction from "./Reaction";
import Rink from "./Rink";
import GameTime from "./GameTime";
import Teams from "./Teams";
import Score from "./Score";
import { useSelector } from "react-redux";

const Game = () => {
  const actionsToDisplay = useSelector(
    (state) => state.actionList.actionsToDisplay
  );

  return (
    <div className="game-wrapper">
      <div className="game-information">
        <div className="game-information-wrapper">
          <div className="game">
            <Rink />
            <GameTime />
          </div>
          <div className="game-score-wrapper">
            <Teams />
            <Score />
          </div>
        </div>
        <div className="events-wrapper">
          <Reaction />
          <div className="events-wrapper-content">
            {actionsToDisplay.map((action) => {
              return (
                <EventList
                  id_marks={action.mID}
                  uuid={action.mX}
                  teams={action.mTeam}
                  action={action.mActionType}
                  player={action.player}
                  time={action.mActionButtonNumber}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className="button-wrapper">
        <Action />
        <Subaction />
      </div>
    </div>
  );
};

export default Game;
