import React from "react";

const PlayerDiodes = (props) => {
  const { players } = props;

  return (
    <div className="players-diode-wrapper">
      <div
        className="score-diode"
        style={{
          backgroundColor:
            players === 6 ? "rgba(121, 255, 118, 1)" : "transparent",
        }}
      ></div>
      <div
        className="score-diode"
        style={{
          backgroundColor:
            players >= 5 ? "rgba(121, 255, 118, 0.9)" : "transparent",
        }}
      ></div>
      <div
        className="score-diode"
        style={{
          backgroundColor:
            players >= 4 ? "rgba(121, 255, 118, 0.8)" : "transparent",
        }}
      ></div>
      <div
        className="score-diode"
        style={{
          backgroundColor:
            players > 3 ? "rgba(121, 255, 118, 0.7)" : "rgba(255, 0, 0, 1)",
        }}
      ></div>
      <div
        className="score-diode"
        style={{
          backgroundColor:
            players > 3 ? "rgba(121, 255, 118, 0.6)" : "rgba(255, 0, 0, 0.7)",
        }}
      ></div>
      <div
        className="score-diode"
        style={{
          backgroundColor:
            players > 3 ? "rgba(121, 255, 118, 0.5)" : "rgba(255, 0, 0, 0.3)",
        }}
      ></div>
    </div>
  );
};

export default PlayerDiodes;
