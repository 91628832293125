import React from "react";
import puck from "../images/puck.png";
import { useSelector, useDispatch } from "react-redux";
import { setHomeFaceOff, setGuestFaceOff } from "../redux/slices/MarkInfoSlice";

export default function PuckHolder(props) {
  const { holderPosition, currentPuckPosition, style } = props;
  const globalInfo = useSelector((state) => state.globalInfo);
  const dispatch = useDispatch();

  const displayPuck = () => {
    if (globalInfo.mTeam === globalInfo.teamOnRight) {
      return holderPosition.fromRight === currentPuckPosition;
    } else {
      return holderPosition.fromLeft === currentPuckPosition;
    }
  };

  return (
    <div>
      <span
        className="rim-hotspot"
        style={{
          ...style,
          position: "absolute",
          cursor: "pointer",
          display: "block",
          zIndex: 5,
          overflow: "hidden",
          backgroundImage: displayPuck() ? `url(${puck})` : "none",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "2rem",
        }}
        onClick={() => {
          // if team on right is selected set the position form right point of view else set it from left point of view
          if (globalInfo.teamOnRight === globalInfo.mTeam) {
            // if home team is selected dispatch for home team else dispatch for guest team
            if (globalInfo.gHomeTeamShortName === globalInfo.mTeam) {
              dispatch(setHomeFaceOff(holderPosition.fromRight));
              dispatch(setGuestFaceOff(holderPosition.fromLeft));
            } else {
              dispatch(setHomeFaceOff(holderPosition.fromLeft));
              dispatch(setGuestFaceOff(holderPosition.fromRight));
            }
          } else {
            if (globalInfo.gHomeTeamShortName === globalInfo.mTeam) {
              dispatch(setHomeFaceOff(holderPosition.fromLeft));
              dispatch(setGuestFaceOff(holderPosition.fromRight));
            } else {
              dispatch(setHomeFaceOff(holderPosition.fromRight));
              dispatch(setGuestFaceOff(holderPosition.fromLeft));
            }
          }
        }}
      ></span>
    </div>
  );
}
