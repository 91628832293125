function PlayersNumberLast() {
  return (
    <div className="players-number-last">
      <div className="players-number-L1">
        <button className="L1">L1</button>
      </div>
      <div className="players-number-F1D1">
        <button className="F1">F1</button>
        <button className="D1">D1</button>
      </div>
      <div className="players-number">
        <div className="players-number-up">
          <button className="up2">9</button>
          <button className="up2">12</button>
          <button className="up2">19</button>
        </div>
        <div className="players-number-down">
          <button className="down2">3</button>
          <button className="down2">4</button>
        </div>
      </div>
      <div className="players-number-others">
        <div className="players-number-others-up">
          <button className="up4">25</button>
        </div>
        <div className="players-number-others-down">
          <button className="down4">68</button>
          <button className="down4">54</button>
        </div>
      </div>
    </div>
  );
}

export default PlayersNumberLast;
