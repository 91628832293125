import "./App.css";
import React, { useEffect } from "react";
import NavBar from "./component/NavBar";
import Game from "./component/Game";
import Players from "./component/Players";
import { fillGlobalInfo } from "./redux/slices/GlobalInfoSlice";
import { setTeam } from "./redux/slices/MarkInfoSlice";
// selector for getting state, dispatch for dispatching actions to reducers (editing state)
import { useDispatch } from "react-redux";

import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const dispatch = useDispatch();

  // nastavenie hodnot do marky
  useEffect(() => {
    dispatch(
      fillGlobalInfo({
        gGuestTeamLongName: "Finland",
        gGuestTeamShortName: "FIN",
        gHomeTeamLongName: "Slovakia",
        gHomeTeamShortName: "SVK",
        gCategory: "U20",
        gDateTime: new Date().toISOString(),
        mTeam: "SVK",
        teamOnLeft: "SVK",
        teamOnRight: "FIN",
      })
    );
  }, []);

  return (
    <div className="App">
      <div className="navBar-wrapper">
        <NavBar />
      </div>
      <div className="content-wrapper">
        <Game />
        <Players />
      </div>
    </div>
  );
}

export default App;
