import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import StopIcon from "@material-ui/icons/Stop";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useSelector, useDispatch } from "react-redux";
import { FaPlay, FaStop, FaPause } from "react-icons/fa";
import {
  addSecond,
  subtractSecond,
  addMinute,
  subtractMinute,
  startTimer,
  pauseTimer,
  setTime,
  resetTo20Minutes,
} from "../redux/slices/GameTimeSlice";
import { increasePeriod } from "../redux/slices/MarkInfoSlice";
import React from "react";

const GameTime = () => {
  const { time, isRunning } = useSelector((state) => state.gameTime);
  const markInfo = useSelector((state) => state.markInfo);
  const timeRef = React.useRef(null);
  const dispatch = useDispatch();

  React.useEffect(() => {
    clearInterval(timeRef.current);

    timeRef.current = setInterval(() => {
      if (isRunning) {
        if (time - 1000 <= 0) {
          dispatch(pauseTimer()); // pause the time
          dispatch(setTime(1201000)); // set time back to 20 minutes
          dispatch(increasePeriod());
        }
        dispatch(subtractSecond());
      }
    }, 1000);

    // cleanup on component dismount
    return () => {
      clearInterval(timeRef.current);
    };
  }, [isRunning, time, markInfo.mPeriod]);

  return (
    <div className="game-time-wrapper">
      <div className="game-time">GAME TIME</div>

      <div className="timer-wrapper">
        <div className="left-side">
          <button className="up" onClick={() => dispatch(addMinute())}>
            <KeyboardArrowUpIcon className="button-arrow-icon"></KeyboardArrowUpIcon>
          </button>
          <button className="down" onClick={() => dispatch(subtractMinute())}>
            <KeyboardArrowDownIcon className="button-arrow-icon"></KeyboardArrowDownIcon>
          </button>
        </div>
        <div className="time">
          {new Date(time).toISOString().substring(14, 19)}
        </div>
        <div className="right-side">
          <button
            className="up"
            onClick={() => {
              dispatch(addSecond());
            }}
          >
            <KeyboardArrowUpIcon className="button-arrow-icon"></KeyboardArrowUpIcon>
          </button>
          <button className="down" onClick={() => dispatch(subtractSecond())}>
            <KeyboardArrowDownIcon className="button-arrow-icon"></KeyboardArrowDownIcon>
          </button>
        </div>
      </div>
      <div className="game-buttons">
        <button
          className="play"
          onClick={() =>
            isRunning ? dispatch(pauseTimer()) : dispatch(startTimer())
          }
        >
          {!isRunning ? <FaPlay size="50%" /> : <FaPause size="50%" />}
        </button>
        <button className="stop" onClick={() => dispatch(resetTo20Minutes())}>
          <FaStop size="50%" />
        </button>
      </div>
    </div>
  );
};

export default GameTime;
