import { Dropdown, DropdownButton } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  filterEvents,
  filterPlayers,
  disableFilterPlayers,
  disableFilterEvents,
} from "../redux/slices/FilterSlice";
import { setActionsToDisplay } from "../redux/slices/ActionListSlice";
const Reaction = () => {
  const filters = useSelector((state) => state.filters);
  const actions = useSelector((state) => state.actionList.actions);
  const dispatch = useDispatch();

  return (
    <div className="reaction">
      <button
        className="event"
        onClick={() => {
          if (filters.events) {
            dispatch(disableFilterEvents());
            dispatch(setActionsToDisplay(actions));
          } else {
            dispatch(filterEvents());
            dispatch(
              setActionsToDisplay(
                actions.filter((action) => action.mActionType === "ACTION")
              )
            );
            dispatch(disableFilterPlayers());
          }
        }}
        style={{
          backgroundColor: filters.events ? "#2BB5E9" : "#4D4D4D",
          color: filters.events ? "#000000" : "#2BB5E9",
        }}
      >
        EVENTS
      </button>
      <button
        className="player"
        onClick={() => {
          if (filters.players) {
            dispatch(disableFilterPlayers());
            dispatch(setActionsToDisplay(actions));
          } else {
            dispatch(filterPlayers());
            dispatch(
              setActionsToDisplay(
                actions.filter((action) => action.mActionType === "PLAYER")
              )
            );
            dispatch(disableFilterEvents());
          }
        }}
        style={{
          backgroundColor: filters.players ? "#2BB5E9" : "#4D4D4D",
          color: filters.players ? "#000000" : "#2BB5E9",
        }}
      >
        PLAYERS
      </button>
      <div className="others">
        <DropdownButton
          title="..."
          variant="secondary"
          align={{ lg: "end" }}
          style={{ ariaExpanded: "false" }}
        >
          <Dropdown.Item href="#/action-1">New Match</Dropdown.Item>
          <Dropdown.Item href="#/action-2">Open Match</Dropdown.Item>
          <Dropdown.Item href="#/action-3">Save Match</Dropdown.Item>
          <Dropdown.Item href="#/action-4">Find and Replace</Dropdown.Item>
          <Dropdown.Item href="#/action-5">
            Create a Synchro tag (F4)
          </Dropdown.Item>
        </DropdownButton>
      </div>
    </div>
  );
};

export default Reaction;
