import React from "react";
import { v4 as uuidv4 } from "uuid";
import { genNextID } from "../redux/slices/MarkIDSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  addAction,
  addActionsToDisplay,
} from "../redux/slices/ActionListSlice";
import {
  setLastUsedButton,
  setLastCreatedMarkUUID,
} from "../redux/slices/LastUsedActionSlice";
import { calculateIceTime, formatTimeToMMSS } from "../utils";

function ActionButton(props) {
  const filters = useSelector((state) => state.filters);
  const globalInfo = useSelector((state) => state.globalInfo);
  const markInfo = useSelector((state) => state.markInfo);
  const markId = useSelector((state) => state.markId.value);
  const gameTime = useSelector((state) => state.gameTime);
  const dispatch = useDispatch();

  const createMark = () => {
    const uuid = uuidv4();
    dispatch(
      addAction({
        mID: markId,
        mX: uuid,
        ...globalInfo,
        ...markInfo,
        mIcetime: formatTimeToMMSS(
          calculateIceTime(markInfo.mPeriod, gameTime.time)
        ),
        mActionType: actionType,
        mActionButtonNumber: actionButtonNumber,
        mBefore,
        mAfter,
        mActionColor: actionColor,
        mCoachActionColor: coachColor,
        mAction: title,
      })
    );

    // if filter is applied and added item should be filtered out don't add to display actions
    if (filters.events && actionType === "PLAYER") {
    } else if (filters.players && actionType === "ACTION") {
    } else {
      dispatch(
        addActionsToDisplay({
          mID: markId,
          mX: uuid,
          ...globalInfo,
          ...markInfo,
          mActionType: actionType,
          mActionButtonNumber: actionButtonNumber,
          mBefore,
          mAfter,
          mActionColor: actionColor,
          mCoachActionColor: coachColor,
          mAction: title,
        })
      );
    }

    dispatch(setLastCreatedMarkUUID(uuid));
    dispatch(genNextID());
  };

  const {
    title,
    keyShortcut,
    actionType,
    actionButtonNumber,
    mBefore,
    mAfter,
    actionColor,
    coachColor,
    subAction,
  } = props;

  return (
    <button
      className="click"
      style={{
        color: actionColor,
        padding: "0",
        margin: "0",
        border: "0",
      }}
      onClick={() => {
        // set the last updated button
        dispatch(setLastUsedButton({ actionButtonNumber, subAction }));
        // create mark
        createMark();
      }}
    >
      <div className="action-button-content-container">
        {title}
        <br />
        {"(" + keyShortcut + ")"}

        {/* if color is not white draw the triangle */}
        {actionColor !== "#ffffff" && (
          <div
            className="triangle"
            // change color of the triangle depending on the actionColor of the button
            style={{
              borderColor: `transparent transparent ${actionColor} transparent`,
            }}
          ></div>
        )}
      </div>
    </button>
  );
}

export default ActionButton;
