export const buttonsData = [
  {
    title: "COACH 1",
    keyShortcut: "C",
    actionType: "ACTION",
    actionButtonNumber: 1,
    mBefore: 1,
    mAfter: 1,
    actionColor: "#ffffff",
    coachColor: "#32a852",
    subAction: [
      { subaction_id: 1, name: "CONTROL", shortName: "CR" },
      { subaction_id: 2, name: "PREASURE", shortName: "PS" },
      { subaction_id: 3, name: "GOALIE", shortName: "GL" },
      { subaction_id: 4, name: "ICING", shortName: "ICI" },
      { subaction_id: 5, name: "FORECHECK", shortName: "FRC" },
      { subaction_id: 6, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 7, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 8, name: "NZ", shortName: "NZ" },
    ],
  },
  {
    title: "GOALIE",
    keyShortcut: "B",
    actionType: "ACTION",
    actionButtonNumber: 2,
    mBefore: 5,
    mAfter: 5,
    actionColor: "#ffffff",
    coachColor: "#32a852",
    subAction: [
      { subaction_id: 1, name: "ICING-B", shortName: "ICI" },
      { subaction_id: 2, name: "OFFSIDE-B", shortName: "OFFS" },
      { subaction_id: 3, name: "GOALIE-B", shortName: "GL" },
      { subaction_id: 4, name: "CONTROL-B", shortName: "CR" },
      { subaction_id: 5, name: "FORECHECK-B", shortName: "FRC" },
      { subaction_id: 6, name: "FACEOFF-B", shortName: "FO" },
      { subaction_id: 7, name: "PREASURE-B", shortName: "PS" },
    ],
  },
  {
    title: "POWER PLAY 1",
    keyShortcut: "U",
    actionType: "ACTION",
    actionButtonNumber: 3,
    mBefore: 1,
    mAfter: 1,
    actionColor: "#ffffff",
    coachColor: "#32a852",
    subAction: [
      { subaction_id: 1, name: "PREASURE", shortName: "PS" },
      { subaction_id: 2, name: "FORECHECK", shortName: "FRC" },
      { subaction_id: 3, name: "NZ", shortName: "NZ" },
      { subaction_id: 4, name: "CONTROL", shortName: "CR" },
      { subaction_id: 5, name: "GOALIE", shortName: "GL" },
      { subaction_id: 6, name: "ICING", shortName: "ICI" },
    ],
  },
  {
    title: "BREAKOUT",
    keyShortcut: "W",
    actionType: "ACTION",
    actionButtonNumber: 4,
    mBefore: 1,
    mAfter: 1,
    actionColor: "#ffffff",
    coachColor: "#32a852",
    subAction: [
      { subaction_id: 1, name: "CONTROL", shortName: "CR" },
      { subaction_id: 2, name: "PREASURE", shortName: "PS" },
      { subaction_id: 3, name: "GOALIE", shortName: "GL" },
      { subaction_id: 4, name: "ICING", shortName: "ICI" },
      { subaction_id: 5, name: "FORECHECK", shortName: "FRC" },
      { subaction_id: 6, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 7, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 8, name: "NZ", shortName: "NZ" },
      { subaction_id: 9, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 10, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 11, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 12, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 13, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 14, name: "OFFSIDE", shortName: "OFFS" },
    ],
  },
  {
    title: "OTHER",
    keyShortcut: "O",
    actionType: "ACTION",
    actionButtonNumber: 5,
    mBefore: 5,
    mAfter: 10,
    actionColor: "#FAFF10",
    coachColor: "#32a852",
    subAction: [
      { subaction_id: 1, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 2, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 3, name: "CONTROL", shortName: "CR" },
      { subaction_id: 4, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 5, name: "GOALIE", shortName: "GL" },
      { subaction_id: 6, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 7, name: "ICING", shortName: "ICI" },
      { subaction_id: 8, name: "FORECHECK", shortName: "FRC" },
      { subaction_id: 9, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 10, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 11, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 12, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 13, name: "PREASURE", shortName: "PS" },
    ],
  },
  {
    title: "SHOT",
    keyShortcut: "S",
    actionType: "ACTION",
    actionButtonNumber: 6,
    mBefore: 1,
    mAfter: 1,
    actionColor: "#ffffff",
    coachColor: "#32a852",
    subAction: [
      { subaction_id: 1, name: "CONTROL", shortName: "CR" },
      { subaction_id: 2, name: "GOALIE", shortName: "GL" },
      { subaction_id: 3, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 4, name: "GOALIE", shortName: "GL" },
      { subaction_id: 5, name: "ICING", shortName: "ICI" },
      { subaction_id: 6, name: "GOALIE", shortName: "GL" },
      { subaction_id: 7, name: "FORECHECK", shortName: "FRC" },
      { subaction_id: 8, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 9, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 10, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 11, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 12, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 13, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 14, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 15, name: "PREASURE", shortName: "PS" },
    ],
  },
  {
    title: "OTHER",
    keyShortcut: "O",
    actionType: "ACTION",
    actionButtonNumber: 7,
    mBefore: 1,
    mAfter: 1,
    actionColor: "#ffffff",
    coachColor: "#32a852",
    subAction: [
      { subaction_id: 1, name: "CONTROL", shortName: "CR" },
      { subaction_id: 2, name: "GOALIE", shortName: "GL" },
      { subaction_id: 3, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 4, name: "GOALIE", shortName: "GL" },
      { subaction_id: 5, name: "ICING", shortName: "ICI" },
      { subaction_id: 6, name: "GOALIE", shortName: "GL" },
    ],
  },
  {
    title: "MISTAKE",
    keyShortcut: "M",
    actionType: "ACTION",
    actionButtonNumber: 8,
    mBefore: 1,
    mAfter: 1,
    actionColor: "#ffffff",
    coachColor: "#32a852",
    subAction: [
      { subaction_id: 1, name: "CONTROL", shortName: "CR" },
      { subaction_id: 2, name: "GOALIE", shortName: "GL" },
      { subaction_id: 3, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 4, name: "GOALIE", shortName: "GL" },
      { subaction_id: 5, name: "ICING", shortName: "ICI" },
      { subaction_id: 6, name: "GOALIE", shortName: "GL" },
      { subaction_id: 7, name: "FORECHECK", shortName: "FRC" },
      { subaction_id: 8, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 9, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 10, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 11, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 12, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 13, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 14, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 15, name: "PREASURE", shortName: "PS" },
      { subaction_id: 16, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 17, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 18, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 19, name: "PREASURE", shortName: "PS" },
    ],
  },
  {
    title: "PLAY ZONE",
    keyShortcut: "H",
    actionType: "PLAYER",
    actionButtonNumber: 9,
    mBefore: 1,
    mAfter: 1,
    actionColor: "#FF0000",
    coachColor: "#32a852",
    subAction: [
      { subaction_id: 1, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 2, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 3, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 4, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 5, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 6, name: "PREASURE", shortName: "PS" },
      { subaction_id: 7, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 8, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 9, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 10, name: "PREASURE", shortName: "PS" },
    ],
  },
  {
    title: "PLAY ZONE",
    keyShortcut: "H",
    actionType: "ACTION",
    actionButtonNumber: 10,
    mBefore: 1,
    mAfter: 1,
    actionColor: "#FF0000",
    coachColor: "#32a852",
    subAction: [
      { subaction_id: 1, name: "CONTROL", shortName: "CR" },
      { subaction_id: 2, name: "GOALIE", shortName: "GL" },
      { subaction_id: 3, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 4, name: "GOALIE", shortName: "GL" },
      { subaction_id: 5, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 6, name: "PREASURE", shortName: "PS" },
      { subaction_id: 7, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 8, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 9, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 10, name: "PREASURE", shortName: "PS" },
    ],
  },
  {
    title: "COACH K",
    keyShortcut: "K",
    actionType: "ACTION",
    actionButtonNumber: 11,
    mBefore: 1,
    mAfter: 1,
    actionColor: "#FAFF10",
    coachColor: "#32a852",
    subAction: [
      { subaction_id: 1, name: "CONTROL", shortName: "CR" },
      { subaction_id: 2, name: "GOALIE", shortName: "GL" },
      { subaction_id: 3, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 4, name: "GOALIE", shortName: "GL" },
      { subaction_id: 5, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 6, name: "PREASURE", shortName: "PS" },
      { subaction_id: 7, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 8, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 9, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 10, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 11, name: "FACEOFF", shortName: "FO" },
    ],
  },
  {
    title: "INJURY",
    keyShortcut: "R",
    actionType: "PLAYER",
    actionButtonNumber: 12,
    mBefore: 1,
    mAfter: 1,
    actionColor: "#ffffff",
    coachColor: "#32a852",
    subAction: [
      { subaction_id: 1, name: "GOALIE", shortName: "GL" },
      { subaction_id: 2, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 3, name: "PREASURE", shortName: "PS" },
      { subaction_id: 4, name: "CONTROL", shortName: "CR" },
      { subaction_id: 5, name: "GOALIE", shortName: "GL" },
      { subaction_id: 6, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 7, name: "GOALIE", shortName: "GL" },
      { subaction_id: 8, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 9, name: "PREASURE", shortName: "PS" },
      { subaction_id: 10, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 11, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 12, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 13, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 14, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 15, name: "PREASURE", shortName: "PS" },
      { subaction_id: 16, name: "CONTROL", shortName: "CR" },
    ],
  },
  {
    title: "POWER PLAY",
    keyShortcut: "Y",
    actionType: "ACTION",
    actionButtonNumber: 13,
    mBefore: 1,
    mAfter: 1,
    actionColor: "#ffffff",
    coachColor: "#32a852",
    subAction: [
      { subaction_id: 1, name: "PREASURE", shortName: "PS" },
      { subaction_id: 2, name: "FORECHECK", shortName: "FRC" },
      { subaction_id: 3, name: "NZ", shortName: "NZ" },
      { subaction_id: 4, name: "CONTROL", shortName: "CR" },
      { subaction_id: 5, name: "GOALIE", shortName: "GL" },
      { subaction_id: 6, name: "ICING", shortName: "ICI" },
    ],
  },
  {
    title: "CHANCE",
    keyShortcut: "E",
    actionType: "ACTION",
    actionButtonNumber: 14,
    mBefore: 1,
    mAfter: 1,
    actionColor: "#ffffff",
    coachColor: "#32a852",
    subAction: [
      { subaction_id: 1, name: "PREASURE", shortName: "PS" },
      { subaction_id: 2, name: "FORECHECK", shortName: "FRC" },
      { subaction_id: 3, name: "NZ", shortName: "NZ" },
      { subaction_id: 4, name: "CONTROL", shortName: "CR" },
      { subaction_id: 5, name: "GOALIE", shortName: "GL" },
      { subaction_id: 6, name: "ICING", shortName: "ICI" },
      { subaction_id: 7, name: "GOALIE", shortName: "GL" },
      { subaction_id: 8, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 9, name: "PREASURE", shortName: "PS" },
      { subaction_id: 10, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 11, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 12, name: "FACEOFF", shortName: "FO" },
    ],
  },
  {
    title: "BLUE LINE",
    keyShortcut: "I",
    actionType: "ACTION",
    actionButtonNumber: 15,
    mBefore: 1,
    mAfter: 1,
    actionColor: "#ffffff",
    coachColor: "#32a852",
    subAction: [
      { subaction_id: 1, name: "CONTROL", shortName: "CR" },
      { subaction_id: 2, name: "GOALIE", shortName: "GL" },
      { subaction_id: 3, name: "ICING", shortName: "ICI" },
      { subaction_id: 4, name: "GOALIE", shortName: "GL" },
      { subaction_id: 5, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 6, name: "PREASURE", shortName: "PS" },
      { subaction_id: 7, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 8, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 9, name: "FACEOFF", shortName: "FO" },
    ],
  },
  {
    title: "OTHER",
    keyShortcut: "O",
    actionType: "ACTION",
    actionButtonNumber: 16,
    mBefore: 1,
    mAfter: 1,
    actionColor: "#ffffff",
    coachColor: "#32a852",
    subAction: [
      { subaction_id: 1, name: "CONTROL", shortName: "CR" },
      { subaction_id: 2, name: "PREASURE", shortName: "PS" },
      { subaction_id: 3, name: "GOALIE", shortName: "GL" },
      { subaction_id: 4, name: "ICING", shortName: "ICI" },
      { subaction_id: 5, name: "FORECHECK", shortName: "FRC" },
      { subaction_id: 6, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 7, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 8, name: "NZ", shortName: "NZ" },
    ],
  },
  {
    title: "OTHER",
    keyShortcut: "O",
    actionType: "ACTION",
    actionButtonNumber: 17,
    mBefore: 1,
    mAfter: 1,
    actionColor: "#ffffff",
    coachColor: "#32a852",
    subAction: [
      { subaction_id: 1, name: "CONTROL", shortName: "CR" },
      { subaction_id: 2, name: "PREASURE", shortName: "PS" },
      { subaction_id: 3, name: "GOALIE", shortName: "GL" },
      { subaction_id: 4, name: "ICING", shortName: "ICI" },
      { subaction_id: 5, name: "FORECHECK", shortName: "FRC" },
      { subaction_id: 6, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 7, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 8, name: "NZ", shortName: "NZ" },
      { subaction_id: 9, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 10, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 11, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 12, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 13, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 14, name: "OFFSIDE", shortName: "OFFS" },
    ],
  },
  {
    title: "TURNOVER",
    keyShortcut: "T",
    actionType: "ACTION",
    actionButtonNumber: 18,
    mBefore: 1,
    mAfter: 1,
    actionColor: "#ffffff",
    coachColor: "#32a852",
    subAction: [
      { subaction_id: 1, name: "GOALIE", shortName: "GL" },
      { subaction_id: 2, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 3, name: "PREASURE", shortName: "PS" },
      { subaction_id: 4, name: "CONTROL", shortName: "CR" },
      { subaction_id: 5, name: "GOALIE", shortName: "GL" },
      { subaction_id: 6, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 7, name: "GOALIE", shortName: "GL" },
      { subaction_id: 8, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 9, name: "PREASURE", shortName: "PS" },
      { subaction_id: 10, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 11, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 12, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 13, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 14, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 15, name: "PREASURE", shortName: "PS" },
      { subaction_id: 16, name: "CONTROL", shortName: "CR" },
    ],
  },
  {
    title: "TURNOVER",
    keyShortcut: "T",
    actionType: "ACTION",
    actionButtonNumber: 19,
    mBefore: 1,
    mAfter: 1,
    actionColor: "#ffffff",
    coachColor: "#32a852",
    subAction: [
      { subaction_id: 1, name: "CONTROL", shortName: "CR" },
      { subaction_id: 2, name: "PREASURE", shortName: "PS" },
      { subaction_id: 3, name: "GOALIE", shortName: "GL" },
      { subaction_id: 4, name: "ICING", shortName: "ICI" },
      { subaction_id: 5, name: "GOALIE", shortName: "GL" },
      { subaction_id: 6, name: "ICING", shortName: "ICI" },
      { subaction_id: 7, name: "FORECHECK", shortName: "FRC" },
      { subaction_id: 8, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 9, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 10, name: "FORECHECK", shortName: "FRC" },
      { subaction_id: 11, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 12, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 13, name: "NZ", shortName: "NZ" },
    ],
  },
  {
    title: "TURNOVER",
    keyShortcut: "T",
    actionType: "ACTION",
    actionButtonNumber: 20,
    mBefore: 1,
    mAfter: 1,
    actionColor: "#ffffff",
    coachColor: "#32a852",
    subAction: [
      { subaction_id: 1, name: "CONTROL", shortName: "CR" },
      { subaction_id: 2, name: "PREASURE", shortName: "PS" },
      { subaction_id: 3, name: "GOALIE", shortName: "GL" },
      { subaction_id: 4, name: "ICING", shortName: "ICI" },
      { subaction_id: 5, name: "FORECHECK", shortName: "FRC" },
      { subaction_id: 6, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 7, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 8, name: "NZ", shortName: "NZ" },
      { subaction_id: 9, name: "CONTROL", shortName: "CR" },
      { subaction_id: 10, name: "PREASURE", shortName: "PS" },
      { subaction_id: 11, name: "GOALIE", shortName: "GL" },
      { subaction_id: 12, name: "ICING", shortName: "ICI" },
      { subaction_id: 13, name: "FORECHECK", shortName: "FRC" },
      { subaction_id: 14, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 15, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 16, name: "NZ", shortName: "NZ" },
    ],
  },
  {
    title: "PENALTY SHOT",
    keyShortcut: "L",
    actionType: "PLAYER",
    actionButtonNumber: 21,
    mBefore: 1,
    mAfter: 1,
    actionColor: "#ffffff",
    coachColor: "#32a852",
    subAction: [
      { subaction_id: 1, name: "FORECHECK", shortName: "FRC" },
      { subaction_id: 2, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 3, name: "CONTROL", shortName: "CR" },
      { subaction_id: 4, name: "PREASURE", shortName: "PS" },
      { subaction_id: 5, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 6, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 7, name: "FORECHECK", shortName: "FRC" },
      { subaction_id: 8, name: "NZ", shortName: "NZ" },
      { subaction_id: 9, name: "CONTROL", shortName: "CR" },
      { subaction_id: 10, name: "NZ", shortName: "NZ" },
      { subaction_id: 11, name: "ICING", shortName: "ICI" },
      { subaction_id: 12, name: "GOALIE", shortName: "GL" },
      { subaction_id: 13, name: "ICING", shortName: "ICI" },
      { subaction_id: 14, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 15, name: "PREASURE", shortName: "PS" },
      { subaction_id: 16, name: "GOALIE", shortName: "GL" },
    ],
  },
  {
    title: "LINE UP",
    keyShortcut: "Z",
    actionType: "ACTION",
    actionButtonNumber: 22,
    mBefore: 1,
    mAfter: 1,
    actionColor: "#ffffff",
    coachColor: "#32a852",
    subAction: [
      { subaction_id: 1, name: "CONTROL", shortName: "CR" },
      { subaction_id: 2, name: "PREASURE", shortName: "PS" },
      { subaction_id: 3, name: "GOALIE", shortName: "GL" },
      { subaction_id: 4, name: "ICING", shortName: "ICI" },
      { subaction_id: 5, name: "FORECHECK", shortName: "FRC" },
      { subaction_id: 6, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 7, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 8, name: "NZ", shortName: "NZ" },
    ],
  },
  {
    title: "FACE-OFF",
    keyShortcut: "Q",
    actionType: "ACTION",
    actionButtonNumber: 23,
    mBefore: 1,
    mAfter: 1,
    actionColor: "#ffffff",
    coachColor: "#32a852",
    subAction: [
      { subaction_id: 1, name: "NZ", shortName: "NZ" },
      { subaction_id: 2, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 3, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 4, name: "FORECHECK", shortName: "FRC" },
      { subaction_id: 5, name: "ICING", shortName: "ICI" },
      { subaction_id: 6, name: "GOALIE", shortName: "GL" },
      { subaction_id: 7, name: "PREASURE", shortName: "PS" },
      { subaction_id: 8, name: "CONTROL", shortName: "CR" },
      { subaction_id: 9, name: "CONTROL", shortName: "CR" },
      { subaction_id: 10, name: "PREASURE", shortName: "PS" },
      { subaction_id: 11, name: "GOALIE", shortName: "GL" },
      { subaction_id: 12, name: "ICING", shortName: "ICI" },
      { subaction_id: 13, name: "FORECHECK", shortName: "FRC" },
      { subaction_id: 14, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 15, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 16, name: "NZ", shortName: "NZ" },
    ],
  },
  {
    title: "FIGHT",
    keyShortcut: "X",
    actionType: "ACTION",
    actionButtonNumber: 24,
    mBefore: 1,
    mAfter: 1,
    actionColor: "#7CFC00",
    coachColor: "#32a852",
    subAction: [
      { subaction_id: 1, name: "CONTROL", shortName: "CR" },
      { subaction_id: 2, name: "PREASURE", shortName: "PS" },
      { subaction_id: 3, name: "GOALIE", shortName: "GL" },
      { subaction_id: 4, name: "ICING", shortName: "ICI" },
      { subaction_id: 5, name: "FORECHECK", shortName: "FRC" },
      { subaction_id: 6, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 7, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 8, name: "NZ", shortName: "NZ" },
      { subaction_id: 9, name: "NZ", shortName: "NZ" },
      { subaction_id: 10, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 11, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 12, name: "FORECHECK", shortName: "FRC" },
      { subaction_id: 13, name: "ICING", shortName: "ICI" },
      { subaction_id: 14, name: "GOALIE", shortName: "GL" },
      { subaction_id: 15, name: "PREASURE", shortName: "PS" },
      { subaction_id: 16, name: "CONTROL", shortName: "CR" },
    ],
  },
  {
    title: "PENALTY",
    keyShortcut: "P",
    actionType: "ACTION",
    actionButtonNumber: 25,
    mBefore: 1,
    mAfter: 1,
    actionColor: "#ffffff",
    coachColor: "#32a852",
    subAction: [
      { subaction_id: 1, name: "CONTROL", shortName: "CR" },
      { subaction_id: 2, name: "PREASURE", shortName: "PS" },
      { subaction_id: 3, name: "CONTROL", shortName: "CR" },
      { subaction_id: 4, name: "PREASURE", shortName: "PS" },
      { subaction_id: 5, name: "GOALIE", shortName: "GL" },
      { subaction_id: 6, name: "ICING", shortName: "ICI" },
      { subaction_id: 7, name: "GOALIE", shortName: "GL" },
      { subaction_id: 8, name: "ICING", shortName: "ICI" },
      { subaction_id: 9, name: "FORECHECK", shortName: "FRC" },
      { subaction_id: 10, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 11, name: "FORECHECK", shortName: "FRC" },
      { subaction_id: 12, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 13, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 14, name: "NZ", shortName: "NZ" },
      { subaction_id: 15, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 16, name: "NZ", shortName: "NZ" },
    ],
  },
  {
    title: "OTHER",
    keyShortcut: "O",
    actionType: "ACTION",
    actionButtonNumber: 26,
    mBefore: 1,
    mAfter: 1,
    actionColor: "#ffffff",
    coachColor: "#32a852",
    subAction: [
      { subaction_id: 1, name: "CONTROL", shortName: "CR" },
      { subaction_id: 2, name: "FORECHECK", shortName: "FRC" },
      { subaction_id: 3, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 4, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 5, name: "PREASURE", shortName: "PS" },
      { subaction_id: 6, name: "GOALIE", shortName: "GL" },
      { subaction_id: 7, name: "ICING", shortName: "ICI" },
      { subaction_id: 8, name: "NZ", shortName: "NZ" },
    ],
  },
  {
    title: "BACKCHECK",
    keyShortcut: "V",
    actionType: "ACTION",
    actionButtonNumber: 27,
    mBefore: 1,
    mAfter: 1,
    actionColor: "#EE82EE",
    coachColor: "#32a852",
    subAction: [
      { subaction_id: 1, name: "PREASURE", shortName: "PS" },
      { subaction_id: 2, name: "CONTROL", shortName: "CR" },
      { subaction_id: 3, name: "ICING", shortName: "ICI" },
      { subaction_id: 4, name: "GOALIE", shortName: "GL" },
      { subaction_id: 5, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 6, name: "FORECHECK", shortName: "FRC" },
      { subaction_id: 7, name: "NZ", shortName: "NZ" },
      { subaction_id: 8, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 9, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 10, name: "PREASURE", shortName: "PS" },
      { subaction_id: 11, name: "FORECHECK", shortName: "FRC" },
      { subaction_id: 12, name: "ICING", shortName: "ICI" },
      { subaction_id: 13, name: "GOALIE", shortName: "GL" },
      { subaction_id: 14, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 15, name: "NZ", shortName: "NZ" },
      { subaction_id: 16, name: "CONTROL", shortName: "CR" },
    ],
  },
  {
    title: "GOAL",
    keyShortcut: "G",
    actionType: "ACTION",
    actionButtonNumber: 28,
    mBefore: 1,
    mAfter: 1,
    actionColor: "#6495ED",
    coachColor: "#32a852",
    subAction: [
      { subaction_id: 1, name: "PREASURE", shortName: "PS" },
      { subaction_id: 2, name: "FORECHECK", shortName: "FRC" },
      { subaction_id: 3, name: "NZ", shortName: "NZ" },
      { subaction_id: 4, name: "CONTROL", shortName: "CR" },
      { subaction_id: 5, name: "GOALIE", shortName: "GL" },
      { subaction_id: 6, name: "ICING", shortName: "ICI" },
    ],
  },
  {
    title: "GOAL",
    keyShortcut: "G",
    actionType: "ACTION",
    actionButtonNumber: 29,
    mBefore: 1,
    mAfter: 1,
    actionColor: "#6495ED",
    coachColor: "#32a852",
    subAction: [
      { subaction_id: 1, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 2, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 3, name: "CONTROL", shortName: "CR" },
      { subaction_id: 4, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 5, name: "GOALIE", shortName: "GL" },
      { subaction_id: 6, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 7, name: "ICING", shortName: "ICI" },
      { subaction_id: 8, name: "FORECHECK", shortName: "FRC" },
      { subaction_id: 9, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 10, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 11, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 12, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 13, name: "PREASURE", shortName: "PS" },
    ],
  },
  {
    title: "GOAL",
    keyShortcut: "G",
    actionType: "ACTION",
    actionButtonNumber: 30,
    mBefore: 1,
    mAfter: 1,
    actionColor: "#6495ED",
    coachColor: "#32a852",
    subAction: [
      { subaction_id: 1, name: "CONTROL", shortName: "CR" },
      { subaction_id: 2, name: "GOALIE", shortName: "GL" },
      { subaction_id: 3, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 4, name: "GOALIE", shortName: "GL" },
      { subaction_id: 5, name: "ICING", shortName: "ICI" },
      { subaction_id: 6, name: "GOALIE", shortName: "GL" },
      { subaction_id: 7, name: "FORECHECK", shortName: "FRC" },
      { subaction_id: 8, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 9, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 10, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 11, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 12, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 13, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 14, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 15, name: "PREASURE", shortName: "PS" },
      { subaction_id: 16, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 17, name: "FACEOFF", shortName: "FO" },
      { subaction_id: 18, name: "OFFSIDE", shortName: "OFFS" },
      { subaction_id: 19, name: "PREASURE", shortName: "PS" },
      { subaction_id: 20, name: "ICING", shortName: "ICI" },
    ],
  },
];
