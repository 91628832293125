import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  events: false,
  players: false,
};

export const FiltersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    filterEvents: (state) => {
      state.events = true;
    },
    disableFilterEvents: (state) => {
      state.events = false;
    },
    filterPlayers: (state) => {
      state.players = true;
    },
    disableFilterPlayers: (state) => {
      state.players = false;
    },
  },
});

export const {
  filterEvents,
  filterPlayers,
  disableFilterEvents,
  disableFilterPlayers,
} = FiltersSlice.actions;

export default FiltersSlice.reducer;
