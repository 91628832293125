import React from "react";
import iceRink from "../images/iceRink.png";
import PuckHolder from "./PuckHolder";
import { useSelector } from "react-redux";

function Rink() {
  // string representing puck position from left A-I
  // const [puckPosition, setPuckPosition] = React.useState("E");
  const markInfo = useSelector((state) => state.markInfo);
  const globalInfo = useSelector((state) => state.globalInfo);

  const currentPuckPosition =
    globalInfo.mTeam === globalInfo.gHomeTeamShortName
      ? markInfo.mHomeFaceOff
      : markInfo.mGuestFaceOff;

  return (
    <div className="rink">
      <div
        style={{
          position: "relative",
        }}
      >
        <img id="main-image" src={iceRink} width="100%" alt="ice rink" />

        <PuckHolder
          holderPosition={{ fromLeft: "I", fromRight: "A" }}
          currentPuckPosition={currentPuckPosition}
          style={{
            width: "14.5%",
            height: "29.0%",
            left: "74.5%",
            top: "56.7%",
          }}
        />

        <PuckHolder
          holderPosition={{ fromLeft: "H", fromRight: "B" }}
          currentPuckPosition={currentPuckPosition}
          style={{
            width: "14.5%",
            height: "29.0%",
            left: "74.5%",
            top: "13.5%",
          }}
        />

        <PuckHolder
          holderPosition={{ fromLeft: "F", fromRight: "D" }}
          currentPuckPosition={currentPuckPosition}
          style={{
            width: "14.5%",
            height: "29.0%",
            left: "54.4%",
            top: "13.5%",
          }}
        />

        <PuckHolder
          holderPosition={{ fromLeft: "G", fromRight: "C" }}
          currentPuckPosition={currentPuckPosition}
          style={{
            width: "14.5%",
            height: "29.0%",
            left: "54.4%",
            top: "56.7%",
          }}
        />

        <PuckHolder
          holderPosition={{ fromLeft: "E", fromRight: "E" }}
          currentPuckPosition={currentPuckPosition}
          style={{
            width: "14.5%",
            height: "29%",
            left: "42.8%",
            top: "35.3%",
          }}
        />

        <PuckHolder
          holderPosition={{ fromLeft: "D", fromRight: "F" }}
          currentPuckPosition={currentPuckPosition}
          style={{
            width: "14.5%",
            height: "29%",
            left: "31.8%",
            top: "56.7%",
          }}
        />

        <PuckHolder
          holderPosition={{ fromLeft: "C", fromRight: "G" }}
          currentPuckPosition={currentPuckPosition}
          style={{
            width: "14.5%",
            height: "29%",
            left: "31.9%",

            top: "13.5%",
          }}
        />

        <PuckHolder
          holderPosition={{ fromLeft: "A", fromRight: "I" }}
          currentPuckPosition={currentPuckPosition}
          style={{
            width: "14.5%",
            height: "29%",
            left: "11.4%",
            top: "13.5%",
          }}
        />

        <PuckHolder
          holderPosition={{ fromLeft: "B", fromRight: "H" }}
          currentPuckPosition={currentPuckPosition}
          style={{
            width: "14.5%",
            height: "29.0%",
            left: "11.2%",
            top: "56.7%",
          }}
        />

        <p
          className="rim-hotspot"
          style={{
            position: "absolute",
            cursor: "pointer",
            display: "block",
            zIndex: 5,
            overflow: "hidden",
            textAlign: "center",
            color: "rgba(0,0,0,0.5)",
            width: "8.1%",
            height: "15.6%",
            left: "45.8%",
            top: "81.8%",
          }}
        >
          {currentPuckPosition}
        </p>
      </div>
    </div>
  );
}

export default Rink;
