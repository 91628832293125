import React from "react";
import { BsFillCircleFill } from "react-icons/bs";

const ActiveIndicator = (props) => {
  const { isActive, title } = props;

  return (
    <div className="active-indicator-wrapper">
      <div className="active-indicator-container">
        <p className="active-indicator-title">{title}</p>
        <div
          className="active-indicator-value-container"
          style={{ color: isActive ? "#00FF00" : "#FF0000" }}
        >
          <BsFillCircleFill
            color={isActive ? "#00FF00" : "#FF0000"}
            style={{
              marginRight: "0.8rem",
              border: "3px solid #282828",
              borderRadius: "50%",
            }}
          />
          {isActive ? "ACTIVE" : "OFFLINE"}
        </div>
      </div>
    </div>
  );
};

export default ActiveIndicator;
