import slovan from "../images/slovan.png";

function Sign() {
  return (
    <div className="sign">
      <div className="sign-wrapper">
        <p>
          SPECIALLY FOR <br />
          SLOVAN BRATISLAVA
        </p>
        <img className="slovan" src={slovan} alt="slovan" />
      </div>
    </div>
  );
}

export default Sign;
