import PlayersNumberL from "./PlayersNumberL";
import PlayersNumberLast from "./PlayersNumberLast";
import { Tabs, Tab } from "react-bootstrap";

function Players() {
  return (
    <div className="players-wrapper">
      <Tabs
        defaultActiveKey="home"
        id="guest-home-tabs"
        style={{
          border: 0,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Tab eventKey="home" title="HOME">
          <div className="players-number-content" style={{ minHeight: "100%" }}>
            <PlayersNumberL />
            <PlayersNumberL />
            <PlayersNumberL />
            <PlayersNumberL />
            <PlayersNumberLast />
          </div>
        </Tab>
        <Tab eventKey="guest" title="GUEST">
          <div className="players-number-content">
            <PlayersNumberL />
            <PlayersNumberL />
            <PlayersNumberL />
            <PlayersNumberL />
            <PlayersNumberLast />
          </div>
        </Tab>
      </Tabs>

      <div className="players-button">
        <button className="buttonButton">
          Undo latest
          <br /> players
        </button>
        <button className="buttonButton">
          Close all active <br /> players (F7)
        </button>
        <button className="buttonButton">
          Start for active <br /> players (F6)
        </button>
      </div>
    </div>
  );
}

export default Players;
