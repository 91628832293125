import React from "react";

function SubActionButton(props) {
  const { subaction_id, name, shortName } = props;

  return (
    <button className="click">
      {name}
      <br />
      {"(" + shortName + ")"}{" "}
    </button>
  );
}
export default SubActionButton;
