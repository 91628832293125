import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mRealTimeStamp: "",
  mVideoTimeStamp: "",
  mPeriod: 0, // period stored as a number from 0 to 4 representing I, II, III, OT, SN
  mHomePlayers: 6,
  mGuestPlayers: 6,
  mHomeScore: 0,
  mGuestScore: 0,
  mHomeFaceOff: "E",
  mGuestFaceOff: "E",
};

export const MarkInfoSlice = createSlice({
  name: "markInfo",
  initialState,
  reducers: {
    setRealTimeStamp: (state) => {
      state.mRealTimeStamp = new Date().toISOString();
    },
    setVideoTimeStamp: (state, action) => {
      state.mVideoTimeStamp = action.payload;
    },
    increasePeriod: (state) => {
      if (state.mPeriod + 1 >= 5) {
        state.mPeriod = 0;
      } else {
        state.mPeriod += 1;
      }
    },
    addHomePlayer: (state) => {
      if (state.mHomePlayers + 1 <= 6) {
        state.mHomePlayers += 1;
      }
    },
    addGuestPlayer: (state) => {
      if (state.mGuestPlayers + 1 <= 6) {
        state.mGuestPlayers += 1;
      }
    },
    removeHomePlayer: (state) => {
      if (state.mHomePlayers - 1 >= 3) {
        state.mHomePlayers -= 1;
      }
    },
    removeGuestPlayer: (state) => {
      if (state.mGuestPlayers - 1 >= 3) {
        state.mGuestPlayers -= 1;
      }
    },
    addHomeScore: (state) => {
      if (state.mHomeScore + 1 <= 255) {
        state.mHomeScore += 1;
      }
    },
    addGuestScore: (state) => {
      if (state.mGuestScore + 1 <= 255) {
        state.mGuestScore += 1;
      }
    },
    subtractHomeScore: (state) => {
      if (state.mHomeScore - 1 >= 0) {
        state.mHomeScore -= 1;
      }
    },
    subtractGuestScore: (state) => {
      if (state.mGuestScore - 1 >= 0) {
        state.mGuestScore -= 1;
      }
    },
    setHomeFaceOff: (state, action) => {
      state.mHomeFaceOff = action.payload;
    },
    setGuestFaceOff: (state, action) => {
      state.mGuestFaceOff = action.payload;
    },
    fillMarkInfo: (state, action) => {
      Object.assign(state, action.payload);
    },
  },
});

export const {
  setRealTimeStamp,
  setVideoTimeStamp,
  setTeam,
  addHomePlayer,
  addGuestPlayer,
  removeHomePlayer,
  removeGuestPlayer,
  addHomeScore,
  addGuestScore,
  subtractHomeScore,
  subtractGuestScore,
  setHomeFaceOff,
  setGuestFaceOff,
  fillMarkInfo,
  updateIceTime,
  increasePeriod,
} = MarkInfoSlice.actions;

export default MarkInfoSlice.reducer;
