import SettingsIcon from "@material-ui/icons/Settings";

function Settings() {
  return (
    <div className="settings">
      <button className="click1">
        <SettingsIcon style={{ color: "#2BB5E9", fontSize: "xx-large" }} />
      </button>
    </div>
  );
}

export default Settings;
