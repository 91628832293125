import logo1 from "../images/logo1.png";
import vector from "../images/vector.png";
import { Dropdown } from "react-bootstrap";

function Logo() {
  return (
    <div className="logo">
      <div className="logo-wrapper">
        {/* {hlavne menu} */}
        <Dropdown>
          <Dropdown.Toggle
            variant="success"
            id="dropdown-basic"
            style={{
              padding: "0",
              borderColor: "transparent",
              boxShadow: "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <img className="logo1" src={logo1} alt="logo1" />
            <img
              style={{ width: "1.7rem", height: "auto" }}
              src={vector}
              alt="vector"
            ></img>
          </Dropdown.Toggle>

          <Dropdown.Menu
            style={{
              width: "100%",
              margin: "0",
              boxShadow: "none",
              borderColor: "transparent",
            }}
          >
            <Dropdown.Item href="#/action-1">New Match</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Open all</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Save all</Dropdown.Item>
            <Dropdown.Item href="#/action-4">
              Present for
              <Dropdown.Menu>
                <Dropdown.Item eventKey="1">Presentation</Dropdown.Item>
                <Dropdown.Item eventKey="2">Marking</Dropdown.Item>
                <Dropdown.Item eventKey="3">V - Marking</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown.Item>
            <Dropdown.Item href="#/action-5">
              Import
              <Dropdown.Menu>
                <Dropdown.Item eventKey="1">Actions</Dropdown.Item>
                <Dropdown.Item eventKey="2">Subactions</Dropdown.Item>
                <Dropdown.Item eventKey="3">Players</Dropdown.Item>
                <Dropdown.Item eventKey="4">Marks</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown.Item>
            <Dropdown.Item href="#/action-6">
              Export
              <Dropdown.Menu style={{ placeItems: "end" }}>
                <Dropdown.Item eventKey="1">Actions</Dropdown.Item>
                <Dropdown.Item eventKey="2">Subactions</Dropdown.Item>
                <Dropdown.Item eventKey="3">Players</Dropdown.Item>
                <Dropdown.Item eventKey="4">Marks</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown.Item>
            <Dropdown.Item href="#/action-7">Settings</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}

export default Logo;
