import SyncAltIcon from "@material-ui/icons/SyncAlt";
import { useSelector, useDispatch } from "react-redux";
import { increasePeriod } from "../redux/slices/MarkInfoSlice";
import {
  selectHomeTeam,
  selectGuestTeam,
  swapTeams,
} from "../redux/slices/GlobalInfoSlice";

function Teams() {
  const globalInfo = useSelector((state) => state.globalInfo);
  const markInfo = useSelector((state) => state.markInfo);
  const dispatch = useDispatch();

  const period = ["I.", "II.", "III.", "OT", "SN"];

  const handleChangePeriod = () => {
    dispatch(increasePeriod());
  };

  return (
    <div className="teams-wrapper">
      <div className="teams-header">
        <p className="teams-header-home">
          {globalInfo.teamOnLeft === globalInfo.gHomeTeamShortName
            ? "HOME"
            : "GUEST"}
        </p>
        <p className="teams-header-guest">
          {globalInfo.teamOnRight === globalInfo.gHomeTeamShortName
            ? "HOME"
            : "GUEST"}
        </p>
      </div>
      <div className="teams-controls-wrapper">
        <div className="teams-home-wrapper">
          <button
            className="teams-home"
            onClick={() => dispatch(selectHomeTeam())}
            style={{
              backgroundColor:
                globalInfo.teamOnLeft === globalInfo.mTeam
                  ? "#2BB5E9"
                  : "#4D4D4D",
            }}
          >
            {globalInfo.teamOnLeft === globalInfo.gHomeTeamShortName
              ? globalInfo.gHomeTeamLongName
              : globalInfo.gGuestTeamLongName}
            <br />
            {globalInfo.teamOnLeft === globalInfo.gHomeTeamShortName
              ? globalInfo.gHomeTeamShortName
              : globalInfo.gGuestTeamShortName}
          </button>
        </div>

        <div className="teams-mark-wrapper">
          <button className="team-mark1" onClick={() => dispatch(swapTeams())}>
            <SyncAltIcon style={{ color: "#2BB5E9", fontSize: "xx-large" }} />
          </button>
          <button className="team-mark2" onClick={() => handleChangePeriod()}>
            {period[markInfo.mPeriod]}
          </button>
        </div>

        <div className="teams-guest-wrapper">
          <button
            className="teams-guest"
            onClick={() => dispatch(selectGuestTeam())}
            style={{
              backgroundColor:
                globalInfo.teamOnRight === globalInfo.mTeam
                  ? "#2BB5E9"
                  : "#4D4D4D",
            }}
          >
            {globalInfo.teamOnRight === globalInfo.gHomeTeamShortName
              ? globalInfo.gHomeTeamLongName
              : globalInfo.gGuestTeamLongName}
            <br />
            {globalInfo.teamOnRight === globalInfo.gHomeTeamShortName
              ? globalInfo.gHomeTeamShortName
              : globalInfo.gGuestTeamShortName}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Teams;
