import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {
  addHomeScore,
  subtractHomeScore,
  addGuestScore,
  subtractGuestScore,
  addHomePlayer,
  removeHomePlayer,
  addGuestPlayer,
  removeGuestPlayer,
} from "../redux/slices/MarkInfoSlice";
import { useSelector, useDispatch } from "react-redux";
import PlayerDiodes from "./PlayerDiodes";

const Score = () => {
  const markInfo = useSelector((state) => state.markInfo);
  const dispatch = useDispatch();

  return (
    <div className="score-wrapper">
      <div className="score-home-wrapper">
        <div className="score-home-players-wrapper">
          <div className="score-home-players-name">PLAYERS</div>
          <div className="score-home-players-all">
            <div className="score-home-players-content">
              <PlayerDiodes players={markInfo.mHomePlayers} />
              <div className="score-home-players">{markInfo.mHomePlayers}</div>
            </div>
            <div className="score-home-players-button">
              <button onClick={() => dispatch(addHomePlayer())} className="up">
                <KeyboardArrowUpIcon className="button-arrow-icon"></KeyboardArrowUpIcon>
              </button>
              <button
                onClick={() => dispatch(removeHomePlayer())}
                className="down"
              >
                <KeyboardArrowDownIcon className="button-arrow-icon"></KeyboardArrowDownIcon>
              </button>
            </div>
          </div>
        </div>
        <div className="score-home-score-wrapper">
          <div className="score-home-score-name">SCORE</div>
          <div className="score-home-score-all">
            <div className="score-home-score">{markInfo.mHomeScore}</div>
            <div className="score-home-score-button">
              <button onClick={() => dispatch(addHomeScore())} className="up">
                <KeyboardArrowUpIcon className="button-arrow-icon"></KeyboardArrowUpIcon>
              </button>
              <button
                onClick={() => dispatch(subtractHomeScore())}
                className="down"
              >
                <KeyboardArrowDownIcon className="button-arrow-icon"></KeyboardArrowDownIcon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="score-guest-wrapper">
        <div className="score-guest-score-wrapper">
          <div className="score-guest-score-name">SCORE</div>
          <div className="score-guest-score-all">
            <div className="score-guest-score-button">
              <button onClick={() => dispatch(addGuestScore())} className="up">
                <KeyboardArrowUpIcon className="button-arrow-icon"></KeyboardArrowUpIcon>
              </button>
              <button
                onClick={() => dispatch(subtractGuestScore())}
                className="down"
              >
                <KeyboardArrowDownIcon className="button-arrow-icon"></KeyboardArrowDownIcon>
              </button>
            </div>
            <div className="score-guest-score">{markInfo.mGuestScore}</div>
          </div>
        </div>
        <div className="score-guest-players-wrapper">
          <div className="score-guest-players-name">PLAYERS</div>
          <div className="score-guest-players-all">
            <div className="score-guest-players-button">
              <button onClick={() => dispatch(addGuestPlayer())} className="up">
                <KeyboardArrowUpIcon className="button-arrow-icon"></KeyboardArrowUpIcon>
              </button>
              <button
                onClick={() => dispatch(removeGuestPlayer())}
                className="down"
              >
                <KeyboardArrowDownIcon className="button-arrow-icon"></KeyboardArrowDownIcon>
              </button>
            </div>
            <div className="score-guest-players-content">
              <div className="score-guest-players">
                {markInfo.mGuestPlayers}
              </div>

              <PlayerDiodes players={markInfo.mGuestPlayers} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Score;
