import SyncAltIcon from "@material-ui/icons/SyncAlt";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import SpaceBarRoundedIcon from "@material-ui/icons/SpaceBarRounded";
import { removeAction } from "../redux/slices/ActionListSlice";
import { useDispatch } from "react-redux";

const EventList = (props) => {
  const dispatch = useDispatch();
  const { id_marks, teams, action, player, time, uuid } = props;
  // FIXME: when adding events and events exceed the size of container
  // there is a small jump because of wrongly set css properties
  return (
    <div
      className="event-list"
      style={{
        backgroundColor: action !== "PLAYER" && "#4D4D4D",
      }}
    >
      <div
        className="event-list-start"
        style={{
          backgroundColor: action === "PLAYER" && "#2BB5E9",
        }}
      ></div>
      <div
        className="event-list-number"
        style={{
          backgroundColor: action !== "PLAYER" && "#4D4D4D",
        }}
      >
        {id_marks}
      </div>
      <div
        className="event-list-country"
        style={{
          backgroundColor: action !== "PLAYER" && "#4D4D4D",
        }}
      >
        {teams}
      </div>
      <div
        className="event-list-action"
        style={{
          color: "#fff",
          backgroundColor: action !== "PLAYER" && "#4D4D4D",
        }}
      >
        {action}
        {player}
      </div>
      <div
        className="event-list-time"
        style={{
          backgroundColor: action !== "PLAYER" && "#4D4D4D",
        }}
      >
        {time}
      </div>
      <div className="event-list-button">
        <button className="eventListButton">
          <LocalOfferOutlinedIcon
            style={{ color: "#2BB5E9", fontSize: "xx-large" }}
          />
        </button>
        <button className="eventListButton">
          <SyncAltIcon style={{ color: "#2BB5E9", fontSize: "xx-large" }} />
        </button>
        <button className="eventListButton">
          <SpaceBarRoundedIcon
            style={{
              color: "#2BB5E9",
              fontSize: "xx-large",
            }}
          />
        </button>
        <button className="eventListButton">
          <CreateIcon style={{ color: "#2BB5E9", fontSize: "xx-large" }} />
        </button>
        <button
          className="eventListButton"
          onClick={() => dispatch(removeAction({ mX: uuid }))}
        >
          <DeleteIcon style={{ color: "#2BB5E9", fontSize: "xx-large" }} />
        </button>
      </div>
    </div>
  );
};

export default EventList;
