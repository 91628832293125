import Logo from "./Logo";
import Record from "./Record";
import Sign from "./Sign";
import Settings from "./Settings";
import ActiveIndicator from "./ActiveIndicator";

function NavBar() {
  return (
    <div className="navBar">
      <div className="navBar-content-container">
        <Logo />
        <Sign />
        <Record />
        <div className="active-indicator-row">
          <ActiveIndicator title="TRUE NETWORK" isActive={true} />
          <ActiveIndicator title="TRUE INGEST" isActive={false} />
          <ActiveIndicator title="TRUE TIMEKEEPER" isActive={false} />
        </div>
      </div>
      <Settings />
    </div>
  );
}

export default NavBar;
