import ActionButton from "./ActionButton";
import { buttonsData } from "./ButtonsData";

const Action = () => {
  return (
    <div className="action">
      {buttonsData.map((button) => {
        return (
          <ActionButton
            title={button.title}
            keyShortcut={button.keyShortcut}
            actionType={button.actionType}
            actionButtonNumber={button.actionButtonNumber}
            mBefore={button.mBefore}
            mAfter={button.mAfter}
            actionColor={button.actionColor}
            coachColor={button.coachColor}
            subAction={button.subAction ? button.subAction : []}
          />
        );
      })}
    </div>
  );
};

export default Action;
