function Record() {
  return (
    <div className="record">
      <div className="record-wrapper">
        <button className="recording">
          <li>REC</li>
        </button>
        <p>
          RECORDING
          <br />
          <li>RUNNING</li>
        </p>
        <p>
          33:10
          <br />
          ..//video
        </p>
      </div>
    </div>
  );
}

export default Record;
