import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // last pressed button
  // object of buttonNumber and subactions of that button
  button: null,
  // uuid of last created mark
  uuid: "",
};

export const LastUsedActionSlice = createSlice({
  name: "lastUsedAction",
  initialState,
  reducers: {
    setLastUsedButton: (state, action) => {
      state.button = action.payload;
    },
    setLastCreatedMarkUUID: (state, action) => {
      state.uuid = action.payload;
    },
  },
});

export const { setLastCreatedMarkUUID, setLastUsedButton } =
  LastUsedActionSlice.actions;

export default LastUsedActionSlice.reducer;
