/**
 * @param {number} time Time in milliseconds
 * @return {string} Time in format "MM:SS"
 */
export const formatTimeToMMSS = (time) => {
  return new Date(time).toISOString().substring(14, 19);
};
/**
 * @param {number} period Value representing period 0,1,2,3,4 -> I, II, III, OT, SN
 * @param {number} time Time passed in current period in milliseconds
 * @return {number} IceTime in milliseconds
 */
export const calculateIceTime = (period, time) => {
  const periodInMilliseconds = 1200000;
  if (period > 3) {
    return periodInMilliseconds * 3 + periodInMilliseconds / 4;
  } else if (period > 2) {
    return periodInMilliseconds * 3 + (periodInMilliseconds / 4 - time);
  } else {
    return periodInMilliseconds * period + (periodInMilliseconds - time);
  }
};
