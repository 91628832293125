import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  gDateTime: "",
  gCategory: "",
  gHomeTeamLongName: "",
  gHomeTeamShortName: "",
  gGuestTeamLongName: "",
  gGuestTeamShortName: "",
  mTeam: "", // selected Team
  teamOnRight: "",
  teamOnLeft: "",
};

export const GlobalInfoSlice = createSlice({
  name: "globalInfo",
  initialState,
  reducers: {
    // set short and long name for home team
    setHomeTeam: (state, action) => {
      state.gHomeTeamLongName = action.payload.longName;
      state.gHomeTeamShortName = action.payload.shortName;
    },
    // set short and long name for guest team
    setGuestTeam: (state, action) => {
      state.gGuestTeamLongName = action.payload.longName;
      state.gGuestTeamShortName = action.payload.shortName;
    },
    // setDateTime to current date and time in form of ISO string -> 2021-10-26T13:14:49.963Z
    setDateTimeToCurrent: (state) => {
      state.gDateTime = new Date().toISOString();
    },
    setCategory: (state, action) => {
      state.gCategory = action.payload;
    },
    selectHomeTeam: (state) => {
      state.mTeam = state.gHomeTeamShortName;
    },
    selectGuestTeam: (state) => {
      state.mTeam = state.gGuestTeamShortName;
    },
    setTeamOnRight: (state, action) => {
      state.teamOnRight = action.payload;
    },
    setTeamOnLeft: (state, action) => {
      state.teamOnLeft = action.payload;
    },
    swapTeams: (state) => {
      let tempTeam = state.teamOnRight;
      state.teamOnRight = state.teamOnLeft;
      state.teamOnLeft = tempTeam;
    },
    fillGlobalInfo: (state, action) => {
      Object.assign(state, action.payload);
    },
  },
});

export const {
  setHomeTeam,
  setGuestTeam,
  setDateTimeToCurrent,
  setCategory,
  fillGlobalInfo,
  selectHomeTeam,
  selectGuestTeam,
  setTeamOnLeft,
  setTeamOnRight,
  swapTeams,
} = GlobalInfoSlice.actions;

export default GlobalInfoSlice.reducer;
