import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  actions: [],
  actionsToDisplay: [],
};

export const actionListSlice = createSlice({
  name: "actionList",
  initialState,
  reducers: {
    // TODO: every operation within this list should be async
    addAction: (state, action) => {
      state.actions.push(action.payload);
    },
    removeAction: (state, action) => {
      state.actions = state.actions.filter(
        (item) => item.mX !== action.payload.mX
      );
      state.actionsToDisplay = state.actionsToDisplay.filter(
        (item) => item.mX !== action.payload.mX
      );
    },
    addActionsToDisplay: (state, action) => {
      state.actionsToDisplay.push(action.payload);
    },
    setActionsToDisplay: (state, action) => {
      state.actionsToDisplay = action.payload;
    },
  },
});

export const {
  addAction,
  removeAction,
  setActionsToDisplay,
  addActionsToDisplay,
} = actionListSlice.actions;

export default actionListSlice.reducer;
